import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Überblick",
  "description": "Das Portal unterteilt sich in zwei Benutzeroberflächen -  Das Frontend und das Backend.",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": true,
  "relatedArticles": ["configuration-options"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Das Portal unterteilt sich in zwei Benutzeroberflächen: Das Frontend und das Backend.`}</p>
    <ul>
      <li parentName="ul">{`Das Frontend ist der für jeden Benutzer, insbesondere den Bewerber zugängliche Bereich.`}</li>
      <li parentName="ul">{`Das Backend ist die Managementoberfläche des Karriereportals und bietet Ihnen diverse Einstellungsmöglichkeiten sowie eine Vorabansicht der eingegangenen Bewerbungen.`}</li>
    </ul>
    <p>{`Zunächst werden die Funktionen des Frontend beschrieben. Im Anschluss wird auf die Einstellungsmöglichkeiten des Backend eingegangen.`}</p>
    <p>{`Startseite
Die Startseite des Karriereportals ist zugleich die Liste aller ausgeschriebenen Stellen. Stellen können hier nach vorab festgelegten Kriterien wie Einsatzbereich oder Standort gefiltert und mit einem Freitext-Suchfeld durchsucht werden.`}</p>
    <p>{`Stellenanzeige
Die Stellenanzeige im Online-Portal besteht aus den im Dynamics NAV-Client eingegebenen Inhalten und wird in dem von Ihnen in NAV vorgegebenen Layout dargestellt.`}</p>
    <p>{`Mit einem Klick auf „Bewerben“ gelangt der Bewerber auf den ersten Eingabedialog im Bewerbungsprozess.`}</p>
    <p>{`Bewerbungsprozess`}</p>
    <p>{`In der Regel besteht der Bewerbungsprozess aus folgenden Grundbausteinen:`}</p>
    <ul>
      <li parentName="ul">{`Persönliche Daten`}</li>
      <li parentName="ul">{`Kontaktdaten`}</li>
      <li parentName="ul">{`Dokumentenanhänge`}</li>
      <li parentName="ul">{`Werdegang/Berufserfahrung`}</li>
      <li parentName="ul">{`Ausbildung`}</li>
      <li parentName="ul">{`Fachkompetenzen`}</li>
      <li parentName="ul">{`Einwilligungserklärung zur Speicherung und Nutzung von Daten`}</li>
      <li parentName="ul">{`Evtl. Screening `}{`[1]`}{` `}</li>
    </ul>
    <p>{`[1]`}{` Screening: Während oder nach Abschluss des Bewerbungsprozesses wird ein Fragebogen mit zusätzlichen Einstufungsfragen an potentielle Kandidaten versandt oder zu einer Veranstaltung vor Ort eingeladen.`}</p>
    <p>{`Nach Abschluss des Online-Bewerbungsprozesses wird eine Eingangsbestätigung versandt.`}</p>
    <p>{`Je nach Eignung des Bewerbers für die ausgeschriebene Stelle kann anschließend in Ihrer Bewerbermanagementlösung in Dynamics NAV eine Einladung zu einem Bewerbungsgespräch oder eine schriftliche Absage versandt werden.`}</p>
    <p>{`Persönliche Daten, Kontaktdaten, Einwilligungserklärung
Im ersten Register wird der Bewerber gebeten, seine Stammdaten und Kontaktdaten anzugeben.`}</p>
    <p>{`Abgefragt werden zum Beispiel:`}</p>
    <ul>
      <li parentName="ul">{`Anrede`}</li>
      <li parentName="ul">{`Vorname`}</li>
      <li parentName="ul">{`Nachname`}</li>
      <li parentName="ul">{`Nationalität`}</li>
      <li parentName="ul">{`Geburtsdatum`}</li>
      <li parentName="ul">{`Straße`}</li>
      <li parentName="ul">{`Ort`}</li>
      <li parentName="ul">{`PLZ`}</li>
      <li parentName="ul">{`E-Mail-Adresse`}</li>
      <li parentName="ul">{`Telefonnr.`}</li>
      <li parentName="ul">{`Verfügbarkeitsdatum`}</li>
    </ul>
    <p>{`An dieser Stelle muss der Bewerber der zweckgebundenen Speicherung und Verwendung seiner Daten zustimmen.`}</p>
    <p>{`Dokumentenanhänge
Im Register Dokumente wird der Bewerber gebeten, ein Anschreiben und weitere relevante Dokumentenanhänge im PDF-Format hochzuladen. Es können Pflichtdokumente vorgegeben werden.`}</p>
    <p>{`Werdegang / Berufserfahrung
Im Register Werdegang/Berufserfahrung wird der Bewerber gebeten, seine bisherigen Beschäftigungsverhältnisse aufzulisten.`}</p>
    <p>{`Abgefragt werden gewöhnlich:`}</p>
    <ul>
      <li parentName="ul">{`Beginn und (wenn zutreffend) Ende der Beschäftigung`}</li>
      <li parentName="ul">{`Position`}</li>
      <li parentName="ul">{`Unternehmen/Organisation`}</li>
      <li parentName="ul">{`Stadt, Land`}</li>
      <li parentName="ul">{`Ausbildung`}</li>
    </ul>
    <p>{`Im Register Ausbildung wird der Bewerber gebeten, seine bisherigen (Hoch-) Schulabschlüsse, Berufsausbildungen oder andere Abschlüsse aufzulisten, die für die ausgeschriebene Stelle relevant sind.`}</p>
    <p>{`Abgefragt werden gewöhnlich:`}</p>
    <ul>
      <li parentName="ul">{`Beginn und (wenn zutreffend) Ende der Ausbildung`}</li>
      <li parentName="ul">{`Art der Ausbildung (Studium, Berufsausbildung, Doktorat, etc.)`}</li>
      <li parentName="ul">{`Hochschule / Ausbildungseinrichtung / Ausbildungsunternehmen`}</li>
      <li parentName="ul">{`Stadt, Land`}</li>
    </ul>
    <p>{`Sprachen
Im Register Sprachen wird der Bewerber gebeten, seine Sprachkenntnisse anzugeben. `}</p>
    <p>{`Abgefragt werden gewöhnlich:`}</p>
    <ul>
      <li parentName="ul">{`Sprache`}</li>
      <li parentName="ul">{`Kenntnisstufe (nach vorgegebener Skala) `}</li>
      <li parentName="ul">{`Fachkompetenzen`}</li>
    </ul>
    <p>{`Im Register Fachkompetenzen wird der Bewerber gebeten, seine fachlichen Kompetenzen anzugeben. `}</p>
    <p>{`Abgefragt werden gewöhnlich:`}</p>
    <ul>
      <li parentName="ul">{`Kompetenz (Auswahlliste oder Freitext)`}</li>
      <li parentName="ul">{`Kenntnisstufe (nach vorgegebener Skala) `}</li>
      <li parentName="ul">{`Zusammenfassung`}</li>
    </ul>
    <p>{`An dieser Stelle hat der Bewerber die Möglichkeit seine Bewerbungsunterlagen sorgfältig auf Korrektheit und Vollständigkeit zu prüfen, gegebenenfalls auszudrucken und anschließend abzusenden.`}</p>
    <p>{`Die Bewerbung kann nach dem Absenden durch den Bewerber nicht mehr bearbeitet werden. Dieser hat allerdings jederzeit möglich die übersandte Bewerbung zurückzuziehen und alle zuvor gespeicherten Daten zu löschen.`}</p>
    <p>{`Nachdem die Bewerbungsunterlagen abgesandt wurden, werden diese nach NAV übertragen. Von diesem Zeitpunkt an kann die Bewerbung im Dynamics NAV Client weiter bearbeitet werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      